@import "~bulma/sass/utilities/_all";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// List of all Bulma variables: https://bulma.io/documentation/customize/variables/
$body-font-size: 16px;
$body-line-height: 150%;
$weight-semibold: 600;
$family-sans-serif: Poppins,
  BlinkMacSystemFont,
  -apple-system,
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  sans-serif;
$family-primary: Poppins,
  BlinkMacSystemFont,
  -apple-system,
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  sans-serif;
$small-font-size: 14px;
$black: #002029;
$purple: #8433AA;
$yellow: #FFEC00;
$yellow-dark: #FFA031;
$primary: #452774;
$primary-light: #F1EDF8;
$primary-lighter: #F1EDF8;
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$green: #54B063;
$green-dark: #006380;
$white: #FCFEFF;
$grey: #D2D2D3;
$grey-dark: #9EA4A5;
$grey-darker: #7D738C;
$grey-light: #F1F1F1;
$grey-lighter: #F9F9FB;
$success: #51B15F;
$danger: #FC3858;
// $warning: #FCF2E3;
$warning: #E9A642;
$success-light: #E9F7ED;
$success-lighter: #EDF7EF;
$warning-light: #FCF2E3;
$warning-lighter: #FCF2E3;
$danger-light: #FFE6EA;
$body-color: black;
// $body-overflow-y: auto; // -> créer un bug
$shadow1: 0px 2px 16px rgba(0, 39, 51, 0.2);
$shadow2: 0px 2px 24px rgba(0, 39, 51, 0.2);
$title-color: $primary;
$title-size: 48px;
$subtitle-color: $grey-dark;
$control-radius: 8px;
$input-radius: 8px;
// $control-padding-horizontal: 32px;
$button-padding-horizontal: 32px;
$button-padding-vertical: 16px;
$button-active-color: $grey-lighter;
$box-background-color: $grey-lighter;
$box-radius: 8px;
$box-shadow: 0;
$modal-card-head-background-color: white;
// $modal-background-background-color: white;
$sab: env(safe-area-inset-bottom);

$tooltip-background-color: $primary;
$tooltip-color: $white;
$tooltip-radius: 6px;
@import '@creativebulma/bulma-tooltip';

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "info": (
      $info,
      $info-invert,
      $info-light,
      $info-dark,
    ),
    "success": (
      $success,
      $success-invert,
      $success-light,
      $success-dark,
    ),
    "warning": (
      $warning,
      $warning-invert,
      $warning-light,
      $warning-dark,
    ),
    "danger": (
      $danger,
      $danger-invert,
      $danger-light,
      $danger-dark,
    ),
  ), 
  $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

:export {
  black: $black;
  grey: $grey;
  greyDark: $grey-dark;
  greyDarker: $grey-darker;
  green: $green;
  greenDark: $green-dark;
  primary: $primary;
  primaryLight: $primary-light;
  primaryLighter: $primary-lighter;
  buttonActiveColor: $button-active-color;
  greyLighter: $grey-lighter;
  greyLight: $grey-light;
  greyDark: $grey-dark;
  warning: $warning;
  fontFamily: $family-primary;
  danger: $danger;
  dangerLight: $danger-light;
  sab: $sab;
  successLight: $success-light;
  success: $success;
  shadow2: $shadow2;
  shadow1: $shadow1;
  yellow: $yellow;
  yellowDark: $yellow-dark;
}