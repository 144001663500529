.leaflet-control-attribution svg {
    display: none !important;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 20px;
}

.leaflet-touch .leaflet-bar a {
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 30px;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaflet-bar a {
    border-bottom: 1px solid rgb(231, 227, 227);
}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.custom-fullscreen-button {
    z-index: 401;
    position: absolute;
    top: 10px;
    right: 10px;
    appearance: none;
    -webkit-appearance: none;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 20px;
    color: rgb(41, 41, 41);
    font-size: 21px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-fullscreen-button:hover {
    background: #f3f3f3;
  }

  .leaflet-popup-content {
    margin: 0
  }

  .leaflet-popup-content-wrapper {
    border-radius: 12px;
  }

  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background-color: rgba(255, 255, 255, 1);
    /* backdrop-filter: blur(10px); */
    /* -webkit-backdrop-filter: blur(10px); */
  }