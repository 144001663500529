@import "./variables.scss";
@import "~bulma";

html, body, #root {
  height: 100%;
  min-height: 100%;
  width: 100%;
  background-color: $primary-lighter !important;
}

* { 
  -webkit-tap-highlight-color: transparent;
  /* box-sizing: border-box; */
}

// remove arrow on number fields: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

progress::-webkit-progress-value {
  -webkit-transition: width .4s ease;
  -moz-transition: width .4s ease;
    -o-transition: width .4s ease;
       transition: width .4s ease;
}

.shadow1 {
  box-shadow: $shadow1 !important;
}

.shadow2 {
  box-shadow: $shadow2 !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
  z-index: 0 !important;
}

.select.is-success select {
  background-color: $success-lighter !important;
}

.taginput .is-success.taginput-container.is-focusable,
.is-success.textarea,
.is-success.input {
  background-color: $success-lighter !important;
}

.input, .textarea, .select select {
  background-color: $grey-lighter;
  border-color: transparent;
  // border: none;
  box-shadow: none;
  -webkit-box-shadow: none
}

.input::placeholder {
  color: $grey-dark;
}

.select.is-success-primary select {
  background-color: $primary-light;
  border-color: $primary;
}

.is-success-primary.input {
  background-color: $primary-light;
  border-color: $primary;
}

.input-grey {
  // background-color: transparent;
  // border-color: rgb(211, 211, 211)
  background-color: $grey-lighter;
}

.select-grey select:focus,
.input-grey:focus {
  border-color: grey;
  box-shadow: 0 0 0 0.125em rgba(grey, 0.25);
}

.select-grey.is-success select {
  border-color: $success;
}

.is-success.input-grey {
  background-color: $success-lighter !important;
  border-color: $success;
}